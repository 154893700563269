import type { RouteDefinition, RouteSectionProps } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getSearchResults } from "~/lib/fetcher";
import { Show, Suspense } from "solid-js";
import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import ResultsPage from "~/components/Search/ResultsPage";
import { useEventsContext } from "~/contexts/EventsContext";

export const route = {
  load({ params }) {
    void getSearchResults(params.hash);
  },
} satisfies RouteDefinition;

export default function SearchRoute(props: RouteSectionProps) {
  const results = createAsync(() => getSearchResults(props.params.hash), {
    name: "search",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("search-results");

  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construisez le projet qui vous ressemble avec un promoteur de référence, pour habiter ou investir dans l’immobilier neuf. Vous verrez la différence.",
    },
  };

  return (
    <>
      <Suspense>
        <Show when={results()}>
          <Title>
            Programmes Immobiliers neufs : votre logement neuf avec Cogedim
          </Title>
          <MetaTags tags={[metaDesc]} />
          <ResultsPage results={results()!} />
        </Show>
      </Suspense>
    </>
  );
}
