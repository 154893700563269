export function Gtag(props: { ga_tracking_id: string }) {
  const script = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments)};
    gtag('js', new Date());
    gtag('config', '${props.ga_tracking_id}');`;

  return (
    <>
      <script
        type="text/javascript"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${props.ga_tracking_id}`}
      />
      <script type="text/javascript" innerHTML={script} />
    </>
  );
}
