import type { RouteDefinition, RouteSectionProps } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getPressRelease } from "~/lib/fetcher";
import { Show } from "solid-js";
import { useEventsContext } from "~/contexts/EventsContext";
import { MetaTags } from "~/components/shared/MetaTags";
import PressRelease from "~/components/PressReleases/PressRelease";
import AuthenticatedLinkGuard from "~/components/shared/AuthenticatedLinkGuard";

export const route = {
  load({ params }) {
    void getPressRelease(params.id);
  },
} satisfies RouteDefinition;

export default function PressReleaseRoute(props: RouteSectionProps) {
  const pressRelease = createAsync(() => getPressRelease(props.params.id), {
    name: "press-release",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("press-release");

  return (
    <>
      <Show when={pressRelease()}>
        <AuthenticatedLinkGuard
          link={`/node/${pressRelease()!.drupal_internal__nid}/edit`}
          should404={!pressRelease()!.status}
        >
          <MetaTags tags={pressRelease()!.metatag} />
          <PressRelease pressRelease={pressRelease()!} />
        </AuthenticatedLinkGuard>
      </Show>
    </>
  );
}
