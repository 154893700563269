import { createSignal, Show } from "solid-js";
import Dialog from "~/components/shared/Dialog";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { useEventsContext } from "~/contexts/EventsContext";
import InstantCallbackForm from "../Forms/InstantCallback";
import { ContactPhoneBtn } from "~/components/shared/ContactPhone";

import IconInstantCallBack from "~/img/icons/instant_recall.svg";
import LogoSourdline from "~/img/logos/sourdline-2023.svg";

import "./InstantCallback.css";

const [dialogIsActive, setDialogIsActive] = createSignal(false);

export default function InstantCallback() {
  const [, { sendShowEvent }] = useEventsContext();

  return (
    <>
      <button
        class="btn"
        type="button"
        onClick={() => {
          sendShowEvent("instant-callback", "topbar");
          setDialogIsActive(true);
        }}
        data-test="cta-instant-callback"
      >
        <i aria-hidden="true" class="cog-icon">
          <IconInstantCallBack />
        </i>
        <span>Rappel</span>
      </button>
      <Show when={dialogIsActive()} keyed>
        <Dialog
          title="Rappel immédiat"
          closingCallback={setDialogIsActive}
          horizontalAlignment="flex-end"
          verticalAlignment="flex-start"
          offsetTop={32}
          width={330}
        >
          <InstantCallbackContent />
        </Dialog>
      </Show>
    </>
  );
}

function InstantCallbackContent() {
  const settingsContext = useDrupalSettingsContext();
  return (
    <>
      <div class="instant-callback-content">
        <InstantCallbackForm />
        <div class="gray-phone">
          <ContactPhoneBtn
            eventZone="topbar"
            parentCloseSetter={setDialogIsActive}
          />
          <Show when={settingsContext.sourdline_enabled}>
            <a
              href="https://cogedim.sourdline.com/"
              target="_blank"
              class="sourdline"
              data-test="sourdline"
              title="Espace sourd"
            >
              <LogoSourdline />
            </a>
          </Show>
        </div>
      </div>
    </>
  );
}
