import type { RouteDefinition } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getMagazine } from "~/lib/fetcher";
import { Show, Suspense } from "solid-js";
import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import { useEventsContext } from "~/contexts/EventsContext";
import MagazinePage from "~/components/Article/MagazinePage";
import type { ItemReference } from "~/types/drupal_jsonapi";

export const route = {
  load() {
    void getMagazine();
  },
} satisfies RouteDefinition;

export default function SearchRoute() {
  const magazine = createAsync(() => getMagazine(), {
    name: "magazine",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("magazine");

  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "L’actu du neuf Cogedim met en lumière les aspirations des Français et les nouvelles tendances en matière d’habitat. Un tour d’horizon immersif, ludique et interactif pour ceux qui rêvent ou nourrissent le projet de devenir propriétaire.",
    },
  };

  return (
    <>
      <Suspense>
        <Show when={magazine()}>
          <Title>L’actu du neuf</Title>
          <MetaTags tags={[metaDesc]} />
          <MagazinePage
            top_article={magazine()?.top_article}
            articles={magazine()?.articles as ItemReference[]}
          />
        </Show>
      </Suspense>
    </>
  );
}
