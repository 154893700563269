import { urlRs } from "~/utils/url";
import { Show } from "solid-js";
import { hasNoEscape } from "~/utils/no_escape";

import "./SEOCities.css";

export default function SEOCities() {
  return (
    <>
      <Show when={!hasNoEscape()}>
        <section class="seo-cities" data-test="seo-cities">
          <h3>Villes les plus recherchées</h3>
          <ul>
            <li>
              <a
                href={urlRs(
                  "geographies",
                  "/nouvelle-aquitaine/gironde-33/bordeaux/",
                )}
              >
                Bordeaux
              </a>
            </li>
            <li>
              <a href={urlRs("geographies", "/hauts-de-france/nord-59/lille/")}>
                Lille
              </a>
            </li>
            <li>
              <a
                href={urlRs(
                  "geographies",
                  "/auvergne-rhone-alpes/rhone-69/lyon/",
                )}
              >
                Lyon
              </a>
            </li>
            <li>
              <a
                href={urlRs(
                  "geographies",
                  "/provence-alpes-cote-d-azur/bouches-du-rhone-13/marseille/",
                )}
              >
                Marseille
              </a>
            </li>
            <li>
              <a
                href={urlRs(
                  "geographies",
                  "/occitanie/herault-34/montpellier/",
                )}
              >
                Montpellier
              </a>
            </li>
            <li>
              <a
                href={urlRs(
                  "geographies",
                  "/pays-de-la-loire/loire-atlantique-44/nantes/",
                )}
              >
                Nantes
              </a>
            </li>
            <li>
              <a href={urlRs("geographies", "/ile-de-france/paris-75/")}>
                Paris Île-de-France
              </a>
            </li>
            <li>
              <a
                href={urlRs(
                  "geographies",
                  "/provence-alpes-cote-d-azur/alpes-maritimes-06/nice/",
                )}
              >
                Nice
              </a>
            </li>
            <li>
              <a
                href={urlRs(
                  "geographies",
                  "/provence-alpes-cote-d-azur/bouches-du-rhone-13/aix-provence/",
                )}
              >
                Aix-en-Provence
              </a>
            </li>
            <li>
              <a
                href={urlRs(
                  "geographies",
                  "/occitanie/haute-garonne-31/toulouse/",
                )}
              >
                Toulouse
              </a>
            </li>
          </ul>
        </section>
      </Show>
    </>
  );
}
