import { Show, For, Switch } from "solid-js";

import type { MenuItem } from "~/types/common";
import type { MenuName } from "~/contexts/MenuMobileContext";
import {
  AutopromosRenderer,
  mayHaveShortcode,
} from "./Autopromos/AutopromosRenderer";
import { useEventsContext } from "~/contexts/EventsContext";
import { transliterate } from "~/utils/format";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { Match } from "solid-js";
import { isServer } from "solid-js/web";

type MenuProps = {
  class?: string;
  items: MenuItem[];
  maxLevel?: number;
  name: MenuName;
};

export default function Menu(props: MenuProps) {
  const maxLevel = () => props.maxLevel || -1;

  return (
    <ul class={props.class} data-test="menu-main">
      <For each={props.items}>
        {(item) => (
          <>
            <li class={item.sanitized} data-test={item.sanitized}>
              <MenuItemRender
                item={item}
                currentLevel={1}
                maxLevel={maxLevel()}
              />
            </li>
          </>
        )}
      </For>
    </ul>
  );
}

type MenuItemProps = {
  item: MenuItem;
  currentLevel: number;
  maxLevel?: number;
};

function MenuItemRender(props: MenuItemProps) {
  const [, { sendClick }] = useEventsContext();
  const [viewCtx] = useViewportSizeContext();

  const maxLevel = () => props.maxLevel || -1;

  return (
    <>
      <Switch
        fallback={
          <span
            classList={{
              "expandable-link": !mayHaveShortcode(props.item.name),
            }}
            onClick={(e) => {
              sendClick(`link-${transliterate(props.item.name)}`, "topbar");
              menuItemToggleIsActiveClass(e);
            }}
          >
            {mayHaveShortcode(props.item.name)
              ? AutopromosRenderer({ text: props.item.name })
              : props.item.name}
          </span>
        }
      >
        <Match
          when={props.item.href.trim() !== "" && !props.item.children?.length}
        >
          <a
            href={props.item.href}
            onClick={() => {
              sendClick(`link-${transliterate(props.item.name)}`, "topbar");
            }}
          >
            {props.item.name}
          </a>
        </Match>
        {/* When on small devices and has children, do not follow the link, simply toggle its state */}
        <Match
          when={
            props.item.href.trim() !== "" &&
            props.item.children?.length &&
            isServer
          }
        >
          <a
            href={props.item.href}
            onClick={() => {
              sendClick(`link-${transliterate(props.item.name)}`, "topbar");
            }}
          >
            {props.item.name}
          </a>
        </Match>
        <Match
          when={
            viewCtx.viewPortIsLessThan1280 &&
            props.item.children?.length &&
            !isServer
          }
        >
          <span
            onClick={(e) => {
              sendClick(`link-${transliterate(props.item.name)}`, "topbar");
              menuItemToggleIsActiveClass(e);
            }}
          >
            {props.item.name}
          </span>
        </Match>
      </Switch>

      <Show
        when={
          props.item.children &&
          (props.currentLevel < maxLevel() || maxLevel() === -1)
        }
      >
        <ul
          classList={{ "always-opened": props.item.name === "L'actu du neuf" }}
        >
          <For each={props.item.children}>
            {(child) => (
              <>
                <li
                  class={child.sanitized.replace(/^-/, "")}
                  data-test={child.sanitized.replace(/^-/, "")}
                >
                  <MenuItemRender
                    item={child}
                    currentLevel={props.currentLevel + 1}
                    maxLevel={maxLevel()}
                  />
                </li>
              </>
            )}
          </For>
        </ul>
      </Show>
    </>
  );
}

// Sets the "is-active" class on the clicked menu item and its ancestors.
const menuItemToggleIsActiveClass = function (
  event: MouseEvent & { currentTarget: HTMLSpanElement; target: Element },
) {
  // Keep a reference to the element sending the event.
  const el = event.target;

  // On big devices, directly follow the link.
  if (/*this.viewPortIsMoreThan1279 && */ el.getAttribute("href") !== null) {
    window.location.href = el.getAttribute("href")!;
  }

  // The navigation container which allow us to scope the search
  // for LI items.
  const nav = document.querySelectorAll(".menu-main-part")[0];
  const items = nav.querySelectorAll("li, ul, a, span");

  // Remove all "is-active" class.
  Array.prototype.forEach.call(items, function (item) {
    if (item !== el) {
      item.classList.remove("is-active");
    }
  });

  const getAncestors = function (
    el: Element,
    parentSelector: Element | Document,
  ) {
    if (typeof parentSelector === "undefined") {
      parentSelector = document;
    }

    const parents = [];
    let p = el.parentNode;

    while (p !== parentSelector) {
      const o = p;
      parents.push(o);
      p = o!.parentNode;
    }
    parents.push(parentSelector);

    return parents;
  };

  const menuItemAncestors = getAncestors(el, nav);

  // Apply "is-active" class to ancestors of the clicked item.
  Array.prototype.forEach.call(menuItemAncestors, function (el) {
    el.classList.add("is-active");
  });

  if (el.classList.contains("is-active")) {
    if (el.getAttribute("href") !== null) {
      window.location.href = el.getAttribute("href")!;
    } else {
      // If the element was already active, deactivate it...
      el.classList.remove("is-active");
      // ... as well as its direct parent.
      (el.parentNode! as HTMLElement).classList.remove("is-active");
    }
  } else {
    // Otherwise we can activate it.
    el.classList.add("is-active");
  }
};
