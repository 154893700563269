import { onMount } from "solid-js";

export default function AltoStart() {
  onMount(() => {
    // @ts-expect-error LoadAltoCalcFin is a global function
    if (typeof LoadAltoCalcFin === "function") {
      // @ts-expect-error LoadAltoCalcFin is a global function
      LoadAltoCalcFin();
    }
  });
  return <></>;
}
