import type { Settings } from "~/types/common";

const debug = import.meta.env.VITE_COG_INSTANT_CALLBACK_FORCE_ENABLED == "1";

export function instantCallbackEnabledNow(settings: Settings): boolean {
  if (debug) {
    return true;
  }

  if (!settings.instant_callback.on) {
    return false;
  }
  // Split the data into lines, then map each line to an object
  const serviceTimes = settings.instant_callback.hours
    .split("\n")
    .map((line) => {
      const [day, start, end] = line.split("|").map(Number);
      return { day, start, end };
    });

  const now = new Date();
  const currentDay = now.getDay();
  const currentTime = now.getHours() * 100 + now.getMinutes();

  const todayService = serviceTimes.find(
    (service) => service.day === currentDay,
  );

  return (todayService &&
    currentTime >= todayService.start &&
    currentTime <= todayService.end) as boolean;
}
