import { isServer, NoHydration } from "solid-js/web";

export function GtmNoScript(props: { gtm_container_id: string }) {
  if (isServer) {
    return (
      <NoHydration>
        <noscript>
          <iframe
            src={`//www.googletagmanager.com/ns.html?id=${props.gtm_container_id}`}
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          />
        </noscript>
      </NoHydration>
    );
  }
  return null;
}
