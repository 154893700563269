import type { MenuItem, Settings } from "~/types/common";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { useStackingContext } from "~/contexts/StackingContext";
import { useSearchContext } from "~/contexts/SearchContext";
import { createEffect, createSignal, Show } from "solid-js";
import { createPreventScroll } from "@solid-aria/overlays";
import Menu from "~/components/shared/Menu";
import { urlRs } from "~/utils/url";
import InstantCallback from "~/components/shared/InstantCallback";
import { useEventsContext } from "~/contexts/EventsContext";
import SearchForm from "../Search/SearchForm";
import { hasNoEscape } from "~/utils/no_escape";
import { instantCallbackEnabledNow } from "~/utils/server_func";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { ContactPhoneBtn } from "~/components/shared/ContactPhone";

import IconMenu from "~/img/icons/menu.svg";
import IconClose from "~/img/icons/close.svg";
import IconContactSupport from "~/img/icons/contact_support.svg";
import IconSearch from "~/img/icons/search.svg";

import "./TopBar.css";

type TopBarProps = {
  menuMainItems: MenuItem[];
  settings: Settings;
  forceStacked?: boolean;
};

export default function TopBar(props: TopBarProps) {
  const settingsContext = useDrupalSettingsContext();
  const [viewportSizeProvider] = useViewportSizeContext();
  const [stackingContext] = useStackingContext();
  const [, { sendClick }] = useEventsContext();

  const [searchState, { setSearchBarOpen }] = useSearchContext();
  const [, { incrementPreventScrollLayerCount }] = useStackingContext();

  const [drawerIsOpen, setDrawerIsOpen] = createSignal(false);

  function hideDrawerFromOverlay(event: MouseEvent) {
    if ((event.target as HTMLElement).classList.contains("nav-overlay")) {
      setDrawerIsOpen(false);
    }
  }

  createEffect(() => {
    if (drawerIsOpen() && viewportSizeProvider.viewPortIsLessThan1280) {
      createPreventScroll();
      incrementPreventScrollLayerCount!();
    }
  });

  return (
    <>
      <Show when={!hasNoEscape()}>
        <header
          role="banner"
          data-ga-zone="topbar"
          class="header"
          classList={{
            stacked: stackingContext.isTopBarHeaderStacked,
            "force-stacked": props.forceStacked,
          }}
          data-test="topbar"
        >
          <div class="header-inner">
            <button
              type="button"
              class="btn btn-icon show-menu-main"
              aria-label="Menu"
              onClick={() => setDrawerIsOpen(true)}
              data-test="btn-menu"
            >
              <i aria-hidden="true" class="cog-icon">
                <IconMenu />
              </i>
            </button>
            <a
              href={urlRs("front", "/")}
              title="Accueil"
              rel="home"
              itemProp="name"
              class="logo"
              data-test="logo"
              onClick={() => {
                sendClick("logo", "topbar");
              }}
            >
              <img
                src="/images/logo-cogedim.svg"
                alt="Logo Cogedim"
                height="40"
                width="40"
              />{" "}
              Cogedim
            </a>
            <div
              class="nav-overlay"
              classList={{ open: drawerIsOpen() }}
              onClick={(e) => {
                hideDrawerFromOverlay(e);
              }}
            >
              <nav role="navigation" id="menu-main">
                <button
                  type="button"
                  class="btn btn-icon hide-menu-main"
                  aria-label="Fermer"
                  onClick={() => setDrawerIsOpen(false)}
                  data-test="close"
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconClose />
                  </i>
                </button>
                <div class="menu-main-part">
                  <Menu
                    name="main"
                    items={props.menuMainItems}
                    class="menu-main"
                  />
                </div>
                <Show when={viewportSizeProvider.viewPortIsLessThan1280}>
                  <div class="phone-block">
                    <ContactPhoneBtn
                      eventZone="topbar"
                      parentCloseSetter={setDrawerIsOpen}
                    />
                  </div>

                  <div class="links">
                    <a
                      href="https://www.mon-cogedim.com/"
                      class="btn"
                      title="Espace clients"
                      data-test="customer"
                    >
                      Espace clients
                    </a>
                    <a
                      href={urlRs("press_releases", "/espace-presse/")}
                      target="_blank"
                      class="btn"
                      title="Espace presse"
                      data-test="press"
                      onClick={() => {
                        sendClick("invest-button", "menu");
                      }}
                    >
                      Espace presse
                    </a>{" "}
                    <Show when={props.settings?.faq_enabled}>
                      <a
                        class="btn faq"
                        href={urlRs("faq", "/faq/")}
                        title="Allers vers la FAQ"
                        data-test="faq-mobile"
                        onClick={() => {
                          sendClick("btn-faq", "topbar");
                        }}
                      >
                        <i aria-hidden="true" class="cog-icon">
                          <IconContactSupport />
                        </i>
                        <span>FAQ</span>
                      </a>
                    </Show>
                  </div>
                </Show>
              </nav>
            </div>
            <div class="topbar-tools">
              <div class="search-container-header">
                <button
                  type="button"
                  class="search-engine-toggle-button btn"
                  data-test="search"
                  onClick={() => {
                    sendClick("search-icon", "topbar");
                    setSearchBarOpen!();
                  }}
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconSearch />
                  </i>
                  Trouver un logement
                </button>
                <Show when={searchState.searchBarIsOpen}>
                  <SearchForm
                    enableLocation={true}
                    enableRooms={true}
                    enableBudget={true}
                    enableMarketingSegments={
                      settingsContext.nouveau_neuf_enabled
                    }
                    enableRegulations={true}
                    enableLotTypes={true}
                    enableSaleStates={true}
                    enableAdvancedSearch={true}
                    enableSearchButton={true}
                    enableCloseButton={true}
                  />
                </Show>
              </div>
              <Show when={props.settings?.faq_enabled}>
                <div class="faq-container-header">
                  <a
                    class="btn"
                    href={urlRs("faq", "/faq/")}
                    title="Allers vers la FAQ"
                    data-test="faq-topbar"
                    onClick={() => {
                      sendClick("btn-faq", "topbar");
                    }}
                  >
                    <i aria-hidden="true" class="cog-icon">
                      <IconContactSupport />
                    </i>
                    <span>FAQ</span>
                  </a>
                </div>
              </Show>
              <div class="phone-container-header" data-test="contacts">
                <ContactPhoneBtn eventZone="topbar" fromTopbar />
                <Show when={instantCallbackEnabledNow(settingsContext)}>
                  <InstantCallback />
                </Show>
              </div>
            </div>
          </div>
        </header>
      </Show>
    </>
  );
}
