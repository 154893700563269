import type { RouteDefinition } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getSitemap } from "~/lib/fetcher";
import { Show, Suspense } from "solid-js";
import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import { useEventsContext } from "~/contexts/EventsContext";
import SiteMapPage from "~/components/SiteMap/SiteMapPage";

export const route = {
  load() {
    void getSitemap();
  },
} satisfies RouteDefinition;

export default function () {
  const data = createAsync(() => getSitemap(), {
    name: "sitemap",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("sitemap");

  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construisez le projet qui vous ressemble avec un promoteur de référence, pour habiter ou investir dans l'immobilier neuf. Vous verrez la différence.",
    },
  };

  return (
    <>
      <Suspense>
        <Show when={data()}>
          <Title>
            Immobilier neuf en France : nos programmes immobiliers par région
          </Title>
          <MetaTags tags={[metaDesc]} />
          <SiteMapPage data={data()!} />
        </Show>
      </Suspense>
    </>
  );
}
