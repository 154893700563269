import { encodeStr, openEncodedUrl } from "~/utils/url";

import IconCall from "~/img/icons/call.svg";
import { Show } from "solid-js";

export default function PhoneBlock(props: {
  phoneNumber: string;
  hours?: string;
}) {
  return (
    <>
      <a
        class="phone-block"
        href="#"
        onClick={() => openEncodedUrl(encodeStr(`tel:${props.phoneNumber}`))}
        data-test="phone"
      >
        <span class="phone-button">
          <span class="circle">
            <i aria-hidden="true" class="cog-icon">
              <IconCall />
            </i>
          </span>
          <span class="number" itemProp="telephone">
            {props.phoneNumber}
          </span>
          <span class="infos">Appel non surtaxé</span>
        </span>
        <div class="text">
          <p class="other-phone">
            ou{" "}
            <span itemProp="telephone">
              +33 {props.phoneNumber.substring(1)}
            </span>{" "}
            depuis l’étranger
          </p>
          <Show
            when={props.hours}
            fallback={
              <p>
                7J/7 Lun - Vend de 8h30 à 20h
                <br />
                Sam - Dim de 10h à 18h
              </p>
            }
          >
            <p innerHTML={props.hours} />
          </Show>
        </div>
      </a>
    </>
  );
}
