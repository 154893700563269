import { Portal } from "solid-js/web";
import { Show, createEffect } from "solid-js";
import { createPreventScroll } from "@solid-aria/overlays";

import { useModalFormContext } from "~/contexts/ModalFormContext";
import { useStackingContext } from "~/contexts/StackingContext";

import FormsFactory from "~/components/Forms/Factory/FormsFactory";
import { useFormContext } from "~/contexts/FormContext";

import "./FormModal.css";

import IconClose from "~/img/icons/close.svg";

export default function FormModal() {
  const [modalFormCtx, { closeModalForm }] = useModalFormContext();
  const [
    ,
    { incrementPreventScrollLayerCount, decreasePreventScrollLayerCount },
  ] = useStackingContext();
  const [formStore] = useFormContext();

  createEffect(() => {
    if (modalFormCtx.form) {
      incrementPreventScrollLayerCount!();
      createPreventScroll();
    }
  });

  return (
    <>
      <Show when={modalFormCtx.form}>
        <Portal mount={document.getElementById("form-modal-receiver")!}>
          <div
            class="cog-overlay"
            onClick={() => {
              closeModalForm!();
              decreasePreventScrollLayerCount!();
            }}
          />
          <div class="form-modal-container">
            <div class="form-modal" data-ga-zone="form">
              <div
                class="headline"
                data-test="modal-headline"
                classList={{
                  "with-arrow":
                    String(formStore.values.subject) !== "" &&
                    modalFormCtx.form === "advisor",
                }}
              >
                <span id="form-modal-headline">
                  {modalFormCtx.options?.modalHeadline}
                </span>
                <Show when={modalFormCtx.options?.modalSubtitle} keyed>
                  <span id="form-modal-subtitle" class="subtitle">
                    &nbsp;| {modalFormCtx.options?.modalSubtitle}
                  </span>
                </Show>
                <button
                  type="button"
                  class="btn btn-icon"
                  aria-label="Fermer"
                  onClick={() => {
                    closeModalForm!();
                    decreasePreventScrollLayerCount!();
                  }}
                  data-test="modal-close"
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconClose />
                  </i>
                </button>
              </div>

              <div
                class="form-modal-content"
                id="form-modal-content"
                data-test="modal-content"
              >
                <FormsFactory
                  form={modalFormCtx.form!}
                  wrapper={modalFormCtx.options?.wrapper}
                  lot={modalFormCtx.options?.lot}
                  landing={modalFormCtx.options?.landing}
                  defaultValues={modalFormCtx.options?.defaultValues}
                  formConfig={modalFormCtx.options?.formConfig}
                />
              </div>
            </div>
          </div>
        </Portal>
      </Show>
    </>
  );
}
