import type { RouteDefinition, RouteSectionProps } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getArticle } from "~/lib/fetcher";
import { Show } from "solid-js";
import { useEventsContext } from "~/contexts/EventsContext";
import { MetaTags } from "~/components/shared/MetaTags";
import Article from "~/components/Article/Article";
import AuthenticatedLinkGuard from "~/components/shared/AuthenticatedLinkGuard";

export const route = {
  load({ params }) {
    void getArticle(params.id);
  },
} satisfies RouteDefinition;

export default function ArticleRoute(props: RouteSectionProps) {
  const article = createAsync(() => getArticle(props.params.id), {
    name: "article",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("article");

  return (
    <>
      <Show when={article()}>
        <AuthenticatedLinkGuard
          link={`/node/${article()!.drupal_internal__nid}/edit`}
          should404={!article()!.status}
        >
          <MetaTags tags={article()!.metatag} />
          <Article article={article()!} />
        </AuthenticatedLinkGuard>
      </Show>
    </>
  );
}
