import type { ParentComponent } from "solid-js";
import { createContext, DEV, useContext } from "solid-js";
import { createStore } from "solid-js/store";
import { useWindowSize } from "@solid-primitives/resize-observer";

export type MenuName = "none" | "main" | "footer";

type MenuMobileContextStore = [
  {
    currentMobileActiveMenu: MenuName;
    activeLevel: number;
    currentLabelStack: string[];
    stackLevelClass: string;
    stackCurrentLabel: string;
    currentMobileActiveMenuClass: string;
  },
  {
    setCurrentMobileActiveMenu?: (menuName: MenuName) => void;
    popStackLevel?: () => void;
    pushStackLevelWithLabel?: (
      menuName: MenuName,
      label: string,
      event: MouseEvent & { currentTarget: HTMLLIElement; target: Element },
    ) => void;
  },
];

const MenuMobileContext = createContext<MenuMobileContextStore>([
  {
    currentMobileActiveMenu: null,
    activeLevel: 0,
    currentLabelStack: [],
    stackLevelClass: `active-level-0`,
    stackCurrentLabel: null,
    currentMobileActiveMenuClass: null,
  },
  {},
]);

export const MenuMobileContextProvider: ParentComponent<{
  currentMobileActiveMenu?: string;
}> = (props) => {
  const [state, setState] = createStore({
      currentMobileActiveMenu: null,
      activeLevel: 0,
      currentLabelStack: [],
      stackLevelClass: `active-level-0`,
      stackCurrentLabel: null,
      currentMobileActiveMenuClass: null,
    }),
    store: MenuMobileContextStore = [
      state,
      {
        setCurrentMobileActiveMenu(menuName: MenuName) {
          setState("currentMobileActiveMenu", menuName);
          setState(
            "currentMobileActiveMenuClass",
            `menu-mobile-active-${state.currentMobileActiveMenu}`,
          );
        },
        popStackLevel() {
          if (DEV) {
            console.log("Popping stack…");
          }
          setState("activeLevel", (current) => current - 1);
          setState("currentLabelStack", (current) => {
            current.pop();
            return current;
          });

          setState("stackLevelClass", `active-level-${state.activeLevel}`);
          setState("stackCurrentLabel", state.currentLabelStack.at(-1));

          if (state.activeLevel === 0) {
            setState("currentMobileActiveMenu", "none");
          }

          if (DEV) {
            console.log("New label", state.stackCurrentLabel);
            console.log("Final level", state.activeLevel);
            console.dir(state);
          }
        },
        pushStackLevelWithLabel(
          menuName: string,
          label: string,
          event: MouseEvent & { currentTarget: HTMLLIElement; target: Element },
        ) {
          if (DEV) {
            console.log("Pushing stack", menuName, label);
            console.log("Original level", state.activeLevel);
          }
          const size = useWindowSize();
          if (size.width > 1209) {
            return;
          }

          if (state.activeLevel < 2) {
            setState("activeLevel", (current) => current + 1);
            setState("currentLabelStack", (current) => {
              current.push(label);
              return current;
            });

            setState("stackLevelClass", `active-level-${state.activeLevel}`);

            setState("stackCurrentLabel", state.currentLabelStack.at(-1));
            setState("currentMobileActiveMenu", menuName);
            setState(
              "currentMobileActiveMenuClass",
              `menu-mobile-active-${state.currentMobileActiveMenu}`,
            );

            if (event && event.target) {
              // The CSS class name to add to current selected <li> menu items.
              const activeClassName = "mobile-active";

              const element = event.target as HTMLAnchorElement;
              const clickedListItem = element.parentElement;

              // Remove mobile-active class on all <li> of the navigation.
              const nodeList = window.document.querySelectorAll(
                ".menu-main li, .menu-corp li",
              );

              const elements: HTMLLIElement[] =
                Array.prototype.slice.call(nodeList);

              elements.forEach(function (li) {
                li.classList.remove(activeClassName);
              });

              // Add the mobile-active class on this <li> and its <li>'s ancestors.
              if (clickedListItem) {
                clickedListItem.classList.add(activeClassName);

                let elem = clickedListItem;
                const selector = "li";

                const parents = [];

                // Get matching parent elements
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                for (; elem && elem !== document; elem = elem.parentNode) {
                  if (elem.matches(selector)) {
                    parents.push(elem);
                  }
                }

                parents.forEach(function (li) {
                  li.classList.add(activeClassName);
                });
              }
            }
          }

          if (DEV) {
            console.log("New label", state.stackCurrentLabel);
            console.log("Final level", state.activeLevel);
            console.dir(state);
          }
        },
      },
    ];

  return (
    <MenuMobileContext.Provider value={store}>
      {props.children}
    </MenuMobileContext.Provider>
  );
};

export function useMenuMobileContext() {
  return useContext(MenuMobileContext);
}
