import { createSignal, type Setter, Show } from "solid-js";
import Dialog from "~/components/shared/Dialog";
import PhoneBlock from "~/components/shared/PhoneBlock";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import IconContact from "~/img/icons/contact.svg";
import LogoSourdline from "~/img/logos/sourdline-2023.svg";

import "./InstantCallback.css";
import { type EventZone, useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { useRequestContext } from "~/contexts/RequestContext";

const [contactPhoneModalIsActive, setContactPhoneModalIsActive] =
  createSignal(false);

export function ContactPhoneBtn(props: {
  fromTopbar?: boolean;
  parentCloseSetter?: Setter<boolean>;
  eventZone: EventZone;
}) {
  const [, { sendClick }] = useEventsContext();
  return (
    <>
      <button
        class="btn"
        classList={{
          "contact-us-phone-button": !props.fromTopbar,
        }}
        type="button"
        onClick={() => {
          sendClick("btn-contact", props.eventZone);
          setContactPhoneModalIsActive(true);
          props.parentCloseSetter?.(false);
        }}
        data-test="contact"
      >
        <i aria-hidden="true" class="cog-icon">
          <IconContact />
        </i>
        <span>{props.fromTopbar ? "Contact" : "Contactez-nous"}</span>
      </button>
    </>
  );
}

export default function ContactPhoneDialog() {
  return (
    <Show when={contactPhoneModalIsActive()} keyed>
      <Dialog
        closingCallback={setContactPhoneModalIsActive}
        horizontalAlignment="center"
        verticalAlignment="center"
        additionalClass="phone-contact-dialog"
      >
        <PhoneContactContent />
      </Dialog>
    </Show>
  );
}

function PhoneContactContent() {
  const settingsContext = useDrupalSettingsContext();
  const [, { sendShowEvent, sendClick }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [reqCtx] = useRequestContext();

  return (
    <>
      <div class="phone-contact-content" data-ga-zone="modal">
        <div class="row-dialog">
          <div class="col" data-test="prospect">
            <h3>
              Vous avez un projet
              <br />
              immobilier&nbsp;?
            </h3>
            <PhoneBlock phoneNumber={settingsContext.phone_number} />
            <button
              type="button"
              class="btn"
              onClick={(e) => {
                setContactPhoneModalIsActive(false);
                sendClick("btn-contact-question", "modal");
                sendShowEvent("contact-us", e);
                openModalForm!("contact-us", {
                  defaultValues: { contact_subject: "site_web" },
                  wrapper: reqCtx.wrapper,
                });
                setModalFormHeadline!("Contact");
              }}
              data-test="cta-contact-question"
            >
              Poser une question
            </button>
            <button
              type="button"
              class="btn"
              onClick={(e) => {
                setContactPhoneModalIsActive(false);
                sendClick("btn-contact-advisor", "modal");
                sendShowEvent("contact-us", e);
                openModalForm!("contact-us", {
                  defaultValues: { contact_subject: "contact_advisor" },
                  wrapper: reqCtx.wrapper,
                });
                setModalFormHeadline!("Contact");
              }}
              data-test="cta-contact-advisor"
            >
              Être appelé par un conseiller
            </button>
          </div>
          <div class="col" data-test="customer">
            <h3>
              Vous êtes
              <br />
              déjà client&nbsp;?
            </h3>
            <PhoneBlock
              phoneNumber="09 70 259 259"
              hours="Du lundi au vendredi de 9h30 à 12h30<br>et de 14h à 18h"
            />
            <div class="customer-area">
              <p>Connectez-vous à votre</p>
              <a
                href="https://mon-cogedim.com/"
                target="_blank"
                class="btn"
                data-test="btn-customer-area"
              >
                Espace client
              </a>
            </div>
            <p class="more">
              Vous pouvez également nous écrire sur l'adresse&nbsp;:{" "}
              <a href="mailto:service-client@cogedim.com">
                service-client@cogedim.com
              </a>
            </p>
            <p class="more">
              Nos équipes sont mobilisées pour vous répondre dans les meilleurs
              délais.
            </p>
          </div>
        </div>
        <Show when={settingsContext.sourdline_enabled}>
          <a
            href="https://cogedim.sourdline.com/"
            target="_blank"
            class="sourdline"
            data-test="sourdline"
            title="Espace sourd"
          >
            <LogoSourdline />
          </a>
        </Show>
      </div>
    </>
  );
}
