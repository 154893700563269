import type { InstantCallbackFormInput } from "~/components/Forms/InstantCallbackSchema";

import {
  hasBeenSubmitted,
  type SubmissionFailed,
  submit,
} from "~/components/Forms/api";
import { createEffect, onCleanup, Show } from "solid-js";
import { useSubmission } from "@solidjs/router";
import { useFormContext } from "~/contexts/FormContext";
import { Phone } from "./Fields/Phone";
import { instantCallbackEnabledNow } from "~/utils/server_func";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import { SharedHiddenFields } from "./Fields/SharedHiddenFields";
import toast from "solid-toast";
import { DebugDumper } from "./Utils/DebugDumper";
import type { FormSystemName } from "~/types/form_system_name";
import { useEventsContext } from "~/contexts/EventsContext";

const debug = false; //import.meta.env.VITE_COG_DEBUG_FORMS == "1";

const FORM_NAME: FormSystemName = "instant-callback";

export type InstantCallbackProps = {
  defaultValues?: Partial<InstantCallbackFormInput>;
};

export default function InstantCallback(props: InstantCallbackProps) {
  const [formStore, { setValue: formSetValue }] = useFormContext();
  const [, { sendSubmitEvent }] = useEventsContext();

  const settingsContext = useDrupalSettingsContext();

  createEffect(() => {
    for (const [key, value] of Object.entries(props.defaultValues || {})) {
      formSetValue!(key, value as string);
    }
  });

  const submission = useSubmission(submit);

  const isSubmittedAndSuccessful = () =>
    submission.result &&
    "success" in submission.result &&
    submission.result.success === true &&
    submission.result.crm?.data?.success;

  onCleanup(() => {
    if (hasBeenSubmitted(submission)) {
      submission.clear();
    }
  });

  createEffect(() => {
    // if an error occurs during submission, toasts the error
    if (submission?.result?.crm?.data?.success === false) {
      console.error(submission?.result as SubmissionFailed);
      toast.error("Une erreur est survenue. Merci de réessayer plus tard.", {
        duration: 5000,
      });
    }
  });

  createEffect(() => {
    submission.result?.success && sendSubmitEvent();
  });

  return (
    <>
      <Show when={!isSubmittedAndSuccessful()}>
        <form action={submit} method="post">
          <SharedHiddenFields
            form_id="instant-callback"
            defaultValues={props.defaultValues}
          />

          <Phone
            name="phone"
            value={props.defaultValues?.phone || ""}
            disabled={submission.pending}
          />

          <p class="legals-content">
            Votre numéro ne sera pas conservé et permet uniquement la mise en
            relation avec un conseiller commercial.
          </p>

          <button
            class="btn instant-callback-submit"
            type="submit"
            data-test="cta-submit"
            disabled={submission.pending}
          >
            Rappel immédiat
          </button>

          <Show when={submission.pending}>
            <div class="spinner">
              <i aria-hidden="true" class="loading-spinner" />
            </div>
          </Show>
        </form>
      </Show>
      <Show when={isSubmittedAndSuccessful()}>
        <Show
          when={instantCallbackEnabledNow(settingsContext)}
          fallback={
            <p class="indispo">
              Nos conseillers ne sont plus disponibles. Nous vous invitons à
              rappeler demain.
            </p>
          }
        >
          <p class="confirm">
            Merci,
            <br />
            <br />
            Un conseiller vous rappelle dans quelques minutes.
          </p>
        </Show>
      </Show>
      <Show when={debug} keyed>
        <DebugDumper
          name={FORM_NAME}
          data={{ store: formStore, result: submission.result }}
        />
      </Show>
    </>
  );
}
