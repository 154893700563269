import type { RouteDefinition, RouteSectionProps } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getLanding } from "~/lib/fetcher";
import { Show } from "solid-js";
import { useEventsContext } from "~/contexts/EventsContext";
import { MetaTags } from "~/components/shared/MetaTags";
import Landing from "~/components/Landing/Landing";
import AuthenticatedLinkGuard from "~/components/shared/AuthenticatedLinkGuard";

export const route = {
  load({ params }) {
    void getLanding(params.id);
  },
} satisfies RouteDefinition;

export default function LandingRoute(props: RouteSectionProps) {
  const landing = createAsync(() => getLanding(props.params.id), {
    name: "landing",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("landing");

  return (
    <>
      <Show when={landing()}>
        <AuthenticatedLinkGuard
          link={`/node/${landing()!.drupal_internal__nid}/edit`}
          should404={!landing()!.status}
        >
          <MetaTags tags={landing()!.metatag} />
          <Landing landing={landing()!} />
        </AuthenticatedLinkGuard>
      </Show>
    </>
  );
}
