import { Title } from "@solidjs/meta";
import { createAsync, type RouteDefinition } from "@solidjs/router";
import { Suspense, Show } from "solid-js";
import { MetaTags } from "~/components/shared/MetaTags";
import { useEventsContext } from "~/contexts/EventsContext";
import { getPressReleases } from "~/lib/fetcher";
import PressReleasesPage from "~/components/PressReleases/PressReleasesPage";

export const route = {
  load() {
    void getPressReleases();
  },
} satisfies RouteDefinition;

export default function EspacePresse() {
  const pressReleases = createAsync(() => getPressReleases(), {
    name: "press-releases",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("press-release");

  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Espace presse Cogedim : retrouvez nos communiqués classés par années.",
    },
  };

  return (
    <>
      <Suspense>
        <Show when={pressReleases()}>
          <Title>Espace presse</Title>
          <MetaTags tags={[metaDesc]} />
          <PressReleasesPage pressReleases={pressReleases()!} />
        </Show>
      </Suspense>
    </>
  );
}
