import { DEV, onMount } from "solid-js";
import { utmStoreSet } from "~/lib/utm_store";

export default function UtmCollector() {
  onMount(() => {
    // UTM query GET parameters storage.
    if (globalThis.location && globalThis.location.search) {
      const searchParams = new URLSearchParams(globalThis.location.search);
      const utmParams = new URLSearchParams();
      for (const [key, value] of searchParams) {
        if (key.startsWith("utm_")) {
          utmParams.append(key, value);
        }
      }

      if (Array.from(utmParams.values()).length) {
        DEV &&
          console.log(
            "👾 UTM collector will process",
            Object.fromEntries(utmParams),
          );
        utmStoreSet(utmParams);
      }
    }
  });

  return <> </>;
}
