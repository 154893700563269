import { Show, For, Match, Switch } from "solid-js";

import type { MenuItem } from "~/types/common";
import type { MenuName } from "~/contexts/MenuMobileContext";
import { encodeStr, openEncodedUrl } from "~/utils/url";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import { useRequestContext } from "~/contexts/RequestContext";

type MenuFooterProps = {
  class?: string;
  items: MenuItem[];
  maxLevel?: number;
  name: MenuName;
};

export default function MenuFooter(props: MenuFooterProps) {
  const maxLevel = () => props.maxLevel || -1;

  return (
    <ul class={props.class} data-test="menu">
      <For each={props.items}>
        {(item) => (
          <>
            <li class={item.sanitized}>
              <MenuFooterItemRender
                item={item}
                currentLevel={1}
                maxLevel={maxLevel()}
              />
            </li>
          </>
        )}
      </For>
    </ul>
  );
}

type MenuFooterItemProps = {
  item: MenuItem;
  currentLevel: number;
  maxLevel?: number;
};

function MenuFooterItemRender(props: MenuFooterItemProps) {
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [reqCtx] = useRequestContext();
  const maxLevel = () => props.maxLevel || -1;

  return (
    <>
      <Show
        when={props.item.href}
        fallback={
          <Switch fallback={<span>{props.item.name}</span>}>
            <Match when={props.item.name === "Contact"}>
              <span
                class="lnk"
                onClick={() => {
                  sendShowEvent(`contact-us`, "footer");
                  openModalForm!("contact-us", {
                    wrapper: reqCtx.wrapper,
                  });
                  setModalFormHeadline!("Contact");
                }}
              >
                {props.item.name}
              </span>
            </Match>
            <Match when={props.item.name === "Gérer les cookies"}>
              <span
                class="lnk"
                onClick={() => {
                  // @ts-expect-error Didomi is injected at runtime
                  Didomi.preferences.show();
                }}
              >
                {props.item.name}
              </span>
            </Match>
          </Switch>
        }
      >
        <Show
          fallback={<a href={props.item.href}>{props.item.name}</a>}
          when={props.item.name.includes("#")}
        >
          <span
            class="lnk"
            onClick={() => openEncodedUrl(encodeStr(props.item.href))}
          >
            {props.item.name.replace("#", "")}
          </span>
        </Show>
      </Show>
      <Show
        when={
          props.item.children &&
          (props.currentLevel < maxLevel() || maxLevel() === -1)
        }
      >
        <ul>
          <For each={props.item.children}>
            {(child) => (
              <>
                <li class={child.sanitized}>
                  <MenuFooterItemRender
                    item={child}
                    currentLevel={props.currentLevel + 1}
                    maxLevel={maxLevel()}
                  />
                </li>
              </>
            )}
          </For>
        </ul>
      </Show>
    </>
  );
}
