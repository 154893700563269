import type { FormSystemName } from "~/types/form_system_name";
import type { Landing, Lot, ParagraphForm } from "~/types/drupal_jsonapi";
import type { ProgramWrapper } from "~/utils/program_wrapper";

import { lazy, Switch, Match, Suspense } from "solid-js";

import { utmStoreGet } from "~/lib/utm_store";
import type { OpenModalFormOptions } from "~/contexts/ModalFormContext";
import { getFormEventNameFromCrmObject } from "~/components/Program/Components/ProgramFormInPage";

type FormContainerPropsType = {
  form: FormSystemName;
  wrapper?: ProgramWrapper;
  lot?: Lot;
  landing?: Landing;
  defaultValues?: OpenModalFormOptions["defaultValues"];
  formConfig?: ParagraphForm;
};

const mock = import.meta.env.VITE_COG_DEBUG_FORMS_MOCK_VALUES == "1";

const FormCallback = lazy(() => import("../Callback"));
const FormLeaflet = lazy(() => import("../Leaflet"));
const FormBlueprint = lazy(() => import("../Blueprint"));
const FormAdvisor = lazy(() => import("../Advisor"));
const FormInPage = lazy(() => import("../InPage"));
const FormQualification = lazy(() => import("../Qualification"));
const FormContactUs = lazy(() => import("../ContactUs"));
const FormFinancialStudy = lazy(() => import("../FinancialStudy"));
const FormParking = lazy(() => import("../Parking"));
const FormInvestGuide = lazy(() => import("../InvestGuide"));
const FormSponsorship = lazy(() => import("../Sponsorship"));

export default function FormsFactory(props: FormContainerPropsType) {
  const hiddenFields = {
    base_email: utmStoreGet("utm_source"),
    campaign: utmStoreGet("utm_campaign"),
    source: utmStoreGet("utm_source"),
    medium: utmStoreGet("utm_medium"),
    term: utmStoreGet("utm_term"),
    content: utmStoreGet("utm_content"),
    // eslint-disable-next-line solid/reactivity
    node_nid: props.landing?.drupal_internal__nid,
    // eslint-disable-next-line solid/reactivity
    program_nid: props.wrapper?.program?.drupal_internal__nid,
  };

  const mockValues = {
    ...{
      civility: "02",
      hour: "10_12",
      first_name: "Jérémy",
      last_name: "Chatard",
      email: "jchatard@pm.me",
      phone: "0606060606",
      location: "Orléans",
      destination: "Habiter",
      date: "16/03/2024",
      // SPonsorship Godson 1
      "godson_1.godson_1_civility": "02",
      "godson_1.godson_1_first_name": "Jérémy",
      "godson_1.godson_1_last_name": "Chatard",
      "godson_1.godson_1_address": "11 rue de la liberté",
      "godson_1.godson_1_postal_code": "45000",
      "godson_1.godson_1_city": "Orléans",
      "godson_1.godson_1_phone": "0600000000",
      "godson_1.godson_1_email": "jchatard@pm.me",
      "godson_1.godson_1_program": "2021",
    },
    ...props.defaultValues,
  };

  return (
    <Suspense fallback={<div class="loader" />}>
      <Switch
        fallback={
          <div>
            Form <em>{`${props.form}`}</em> not found.
          </div>
        }
      >
        <Match when={props.form === "callback"}>
          <FormCallback
            defaultValues={mock ? mockValues : props.defaultValues}
            wrapper={props.wrapper}
            landing={props.landing}
            hiddenFields={{ form_id: props.form, ...hiddenFields }}
          />
        </Match>
        <Match when={props.form === "leaflet"}>
          <FormLeaflet
            defaultValues={mock ? mockValues : props.defaultValues}
            wrapper={props.wrapper}
            landing={props.landing}
            hiddenFields={{ form_id: props.form, ...hiddenFields }}
          />
        </Match>
        <Match when={props.form === "blueprint"}>
          <FormBlueprint
            defaultValues={mock ? mockValues : props.defaultValues}
            wrapper={props.wrapper}
            lot={props.lot}
            landing={props.landing}
            hiddenFields={{ form_id: props.form, ...hiddenFields }}
          />
        </Match>
        <Match when={props.form === "advisor"}>
          <FormAdvisor
            defaultValues={mock ? mockValues : props.defaultValues}
            wrapper={props.wrapper}
            landing={props.landing}
            hiddenFields={{ form_id: props.form, ...hiddenFields }}
          />
        </Match>
        <Match when={props.form === "in-page"}>
          <FormInPage
            defaultValues={mock ? mockValues : props.defaultValues}
            wrapper={props.wrapper}
            landing={props.landing}
            formConfig={props.formConfig}
            hiddenFields={{ form_id: props.form, ...hiddenFields }}
            formNameForEvent={getFormEventNameFromCrmObject(
              props.formConfig?.field_crm_object || "unknown",
            )}
          />
        </Match>
        <Match when={props.form === "qualification"}>
          <FormQualification
            defaultValues={mock ? mockValues : props.defaultValues}
            wrapper={props.wrapper}
            landing={props.landing}
            hiddenFields={{ form_id: props.form, ...hiddenFields }}
          />
        </Match>
        <Match when={props.form === "contact-us"}>
          <FormContactUs
            defaultValues={mock ? mockValues : props.defaultValues}
            hiddenFields={{ form_id: props.form, ...hiddenFields }}
          />
        </Match>
        <Match when={props.form === "financial-study"}>
          <FormFinancialStudy
            defaultValues={mock ? mockValues : props.defaultValues}
            wrapper={props.wrapper}
            hiddenFields={{ form_id: props.form, ...hiddenFields }}
          />
        </Match>
        <Match when={props.form === "parking"}>
          <FormParking
            defaultValues={mock ? mockValues : props.defaultValues}
            wrapper={props.wrapper}
            hiddenFields={{ form_id: props.form, ...hiddenFields }}
          />
        </Match>
        <Match when={props.form === "guide-invest"}>
          <FormInvestGuide
            defaultValues={mock ? mockValues : props.defaultValues}
            wrapper={props.wrapper}
            landing={props.landing}
            hiddenFields={{ form_id: props.form, ...hiddenFields }}
          />
        </Match>
        <Match when={props.form === "sponsorship"}>
          <FormSponsorship
            defaultValues={mock ? mockValues : props.defaultValues}
            hiddenFields={{ form_id: props.form, ...hiddenFields }}
          />
        </Match>
      </Switch>
    </Suspense>
  );
}
